import { FirstFormTabs } from '4_widgets';

import { Typography } from '../../../1_shared/ui';

import styles from './FirstForm.module.scss';

const FirstFormSpec = () => (
  <div className={styles.root}>
    <Typography>В РАЗРАБОТКЕ</Typography>
    <FirstFormTabs />
  </div>
);

export default FirstFormSpec;
