import Image1Img from '1_shared/assets/images/to-read/to-read-1@1x.png';
import Image2Img from '1_shared/assets/images/to-read/to-read-2@1x.png';
import Image3Img from '1_shared/assets/images/to-read/to-read-3@1x.png';
import Image4Img from '1_shared/assets/images/to-read/to-read-4@1x.png';

import 'app/styles/global/global.scss';
import './to-read.scss';

const ToRead = () => (
  <section className="to-read">
    <div className="container to-read__container">
      <div className="to-read__inner">
        <div className="to-read__heading">
          <h2 className="to-read__title section-title">Что еще почитать</h2>
          <p className="to-read__subtitle">
            Наш блог. Знание о том, как устроена психология — первый шаг к
            улучшению ментального здоровья
          </p>
        </div>
        <div className="to-read__list-wrapper">
          <ul className="to-read__list">
            <li className="to-read__item">
              <a
                href="https://old.dotherapy.ru/emotsionalno-obraznaya_terapiya"
                className="to-read__link"
              >
                <img
                  src={Image1Img}
                  alt="Эмоционально-образная терапия: как она работает?"
                  className="to-read__img"
                />
                <span className="to-read__type">Статья</span>
                <h3 className="to-read__item-title">
                  Эмоционально-образная терапия: как она работает?
                </h3>
                <div className="to-read_arrow" />
              </a>
            </li>
            <li className="to-read__item">
              <a
                href="https://old.dotherapy.ru/10_neuroticheskih_potrebnostey"
                className="to-read__link"
              >
                <img
                  src={Image2Img}
                  alt="10 невротических потребностей по Карен Хорни"
                  className="to-read__img"
                />
                <span className="to-read__type">Статья</span>
                <h3 className="to-read__item-title">
                  10 невротических потребностей по Карен Хорни
                </h3>
                <div className="to-read_arrow" />
              </a>
            </li>
            <li className="to-read__item">
              <a
                href="https://old.dotherapy.ru/psycholog_psychoanalitic_psychiatrist"
                className="to-read__link"
              >
                <img
                  src={Image3Img}
                  alt="Психолог, психотерапевт, психоаналитик, психиатр — в чём разница?"
                  className="to-read__img"
                />
                <span className="to-read__type">Статья</span>
                <h3 className="to-read__item-title">
                  Психолог, психотерапевт, психоаналитик, психиатр —<br /> в чём
                  разница?
                </h3>
                <div className="to-read_arrow" />
              </a>
            </li>
            <li className="to-read__item">
              <a
                href="https://old.dotherapy.ru/pravila_stressoustoichivosty_na_rabochem_meste"
                className="to-read__link"
              >
                <img
                  src={Image4Img}
                  alt="Правила стрессоустойчивости на рабочем месте"
                  className="to-read__img"
                />
                <span className="to-read__type">Статья</span>
                <h3 className="to-read__item-title">
                  Правила стрессоустойчивости на рабочем месте
                </h3>
                <div className="to-read_arrow" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default ToRead;
