import { ISlicedSpecialistData } from '../../../../1_shared/config/interfaces/ISpecialistData';
import { ISpecialistListFiltersQuery } from '../../../../1_shared/config/interfaces/ISpecialistListFilters';
import {
  MeetingsSingle2,
  SubscribeBlock,
} from '../../../../1_shared/ui';
import { HelpChoose, SpecialistCard } from '../../../../4_widgets';

import styles from '../SpecialistsPage.module.scss';

const SpecialistListByRegion = (props: {
  specList?: ISlicedSpecialistData;
  handleFiltersChange: (filters: ISpecialistListFiltersQuery) => void;
  totalShowedSpecs: number;
  total: number;
  viewMore: () => void;
}) => {
  const { handleFiltersChange, specList, totalShowedSpecs, total, viewMore } =
    props;

    return (
      <div>
        {specList?.first?.map(spec => (
          <SpecialistCard spec={spec} key={spec?.id} showDescription />
        ))}
        <SubscribeBlock />
        {!!specList?.second?.length &&
          specList?.second?.map((spec, index) => (
            <SpecialistCard
              spec={spec}
              key={spec?.id}
              slicedBlock
              className={index === 0 ? styles.block : undefined}
            />
          ))}
        <MeetingsSingle2 />
        {!!specList?.third?.length &&
          specList?.third?.map((spec, index) => (
            <SpecialistCard
              spec={spec}
              key={spec?.id}
              slicedBlock
              className={index === 0 ? styles.block : undefined}
            />
          ))}
        <HelpChoose
          onChange={handleFiltersChange}
          specs={{ specList, total, totalShowedSpecs, viewMore }}
        />
      </div>
    );
  };

export default SpecialistListByRegion;
