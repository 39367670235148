import { Footer, HeroSuccess, Next, ProgramsThanks, ToRead } from '1_shared/ui';
import Page from '1_shared/ui/Page/ui/Page';
import { OrderDetails } from '2_entities';
import { Header } from '4_widgets';

const ReactOrderOk = () => (
  <Page>
    <Header />
    <HeroSuccess />
    <OrderDetails />
    <Next />
    <ProgramsThanks />
    <ToRead />
    <Footer />
  </Page>
);

export default ReactOrderOk;
