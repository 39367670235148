import { Button } from '1_shared/ui';

import 'app/styles/global/global.scss';
import './subscribe-block.scss';

const SubscribeBlock = () => (
  <section className="subscribe-block">
    <div className="container subscribe-block__container">
      <div className="subscribe-block__inner">
        <div className="subscribe-block__heading">
          <h2 className="subscribe-block__title section-title">
            Все еще не нашли «своего» специалиста?
          </h2>
          <p className="subscribe-block__subtitle">
            Обратитесь к нам в телеграмм за помощью
          </p>
          <div className="btnWrap">
            <Button type="primary" style={{ padding: '25px' }}>
              ОБРАТИТЬСЯ В ТЕЛЕГРАММ
            </Button>
          </div>
        </div>
        <div className="subscribe-block__form-wrap">
          <form action="" method="post" className="subscribe-block__form">
            {/* <label htmlFor="subscribe-block-email">ваш email</label> */}
            <input type="email" name="email" id="subscribe-block-email" />
            <button
              type="submit"
              className="subscribe-block__btn btn btn--mini"
            >
              Подписаться на подборку
            </button>
            <span>Без дополнительных рассылок и спама</span>
          </form>
        </div>
      </div>
    </div>
  </section>
);

export default SubscribeBlock;
