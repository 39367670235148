import { useEffect, useMemo } from 'react';
import { Route, Routes as RoutesPath, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import useInterceptors from '../../../1_shared/api/hooks/useInterceptors';
import { Roles } from '../../../1_shared/config/interfaces/Roles';
import { env } from '../../../env';
import { routesConfig } from '../../config/router';
import NotificationContext from '../lib/context/NotificationContext';
import { useAuthContext } from '../lib/hooks/useAuthContext';

const routesConfigBase = routesConfig;

const Routes = () => {
  const { role } = useAuthContext();
  const { contextHolder, messageApi } = useInterceptors();

  // UTM-functional
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params: { [x: string]: string } = {};
    // @ts-ignore
    const paramsStrings: [] = location.search
      ?.split('?')?.[1]
      ?.split('&')
      ?.map(param => param?.split('='))
      ?.map(value => {
        params[value?.[0]] = value?.[1];
        return null;
      });
    if (paramsStrings?.length) {
      axios.post(`${env.REACT_APP_SOCKET_URL}/ns/crm/add-utm`, params);
      navigate(location.pathname);
    }
  }, [location, navigate]);

  const router = useMemo(
    () =>
      routesConfigBase
        .filter(
          route =>
            route.roles.includes(role) ||
            route.roles.includes(Roles.Unauthorized),
        )
        .map(route => ({
          path: route.path,
          element: route.element,
        })),
    [role],
  );

  return (
    <section>
      <NotificationContext.Provider value={{ messageApi }}>
        <RoutesPath>
          {router.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </RoutesPath>
        {contextHolder}
      </NotificationContext.Provider>
    </section>
  );
};

export default Routes;
