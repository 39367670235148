import { RoutePath } from '../../../config/routes';

export const footerLinks = [
  {
    label: 'О платформе',
    url: '/#about',
    isAppLink: false,
  },
  {
    label: 'Частые вопросы',
    url: '/#faq',
    isAppLink: false,
  },
  {
    label: 'Вход на платформу',
    url: RoutePath.LOGIN,
    isAppLink: true,
  },
];
