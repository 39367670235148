import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import dayjs from 'dayjs';

import { Button, Content, Typography } from '1_shared/ui';
import { PreviewImage, SessionTable, TimerSession } from '3_features';

import DotsAnketaMp4 from '../../../1_shared/assets/video/dots_anketa.mp4';
import {RoutePath} from "../../../1_shared/config/routes";
import { configMedia } from '../../../4_widgets/SpecialistCard/ui/config/configMedia';
import { ETypeTable } from '../../SessionsTable/config/interfaces/ETypeTable';
import useInfoSessions from '../model/useInfoSessions';

import styles from './SessionPc.module.scss';

const SessionsPc = () => {
  const { activeSession, futureSessions, pastSessions, isLoading, mutate } =
    useInfoSessions();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {activeSession && (
        <div className={styles.now}>
          <div className={styles.sessionNow}>
            <div className={styles.titleNow}>
              <Typography type="description">АКТИВНЫЙ СЕАНС</Typography>
            </div>
            <div className={styles.cardNow}>
              <PreviewImage
                media={
                  activeSession?.specialist?.avatar
                    ? {
                        id: '1',
                        ...configMedia.mediaContentResponse,
                        previewLink: activeSession?.specialist?.avatar,
                      }
                    : { id: '1', ...configMedia.mediaContentResponse }
                }
              />
              <div className={styles.info}>
                <div className={styles.nameBlock}>
                  <Typography type="title">
                    {activeSession?.specialist.lastName}
                  </Typography>
                  <Typography type="title">
                    {activeSession?.specialist.firstName}
                  </Typography>
                  <Typography type="p" className={styles.weightTitle}>
                    уже ожидает вас
                  </Typography>
                </div>
                <div className={styles.infoBlock}>
                  <div className={styles.textWrap}>
                    <Typography type="subtitle">ДАТА И ВРЕМЯ</Typography>
                    <Typography>
                      {dayjs(
                        activeSession?.start,
                        'YYYY-MM-DDTHH:mm:ss',
                      ).format('DD MMMM, HH:mm')}
                    </Typography>
                  </div>
                  <TimerSession end={activeSession.end} mutate={mutate} />
                </div>
              </div>
              <div className={styles.btnWrap}>
                <Button className={styles.bigBtn} onClick={() => navigate(`${RoutePath.CONFERENCE}/${activeSession.slotId}`)}>
                  ПРИСОЕДИНИТЬСЯ
                </Button>
              </div>
            </div>

            <div className={styles.videoWrap}>
              <video
                src={DotsAnketaMp4}
                className={styles.videoItem}
                width="100%"
                height="auto"
                playsInline
                muted
                loop
                autoPlay
                preload="auto"
              />
            </div>
          </div>

          <Content>
            <div className={styles.fixData}>
              <Typography>
                Заметили ошибку в данных? Поможем исправить
              </Typography>
              <a
                href="https://t.me/do_therapy"
                className="btn btn--light"
                type="primary"
              >
                ПЕРЕЙТИ В ТЕЛЕГРАММ
              </a>
            </div>
          </Content>
        </div>
      )}
      <Content>
        <div className={styles.tableWrapper}>
          <Typography type="title">Будущие сеансы</Typography>
          {futureSessions && !!futureSessions.length ? (
            <SessionTable
              mutate={mutate}
              items={futureSessions || []}
              typeTable={ETypeTable.Future}
            />
          ) : (
            <Typography>В данный момент у вас нет сеансов</Typography>
          )}
        </div>
      </Content>
      <Content>
        <div className={styles.tableWrapper}>
          <Typography type="title">Прошедшие сеансы</Typography>
          {pastSessions && !!pastSessions.length ? (
            <SessionTable
              mutate={mutate}
              items={pastSessions || []}
              typeTable={ETypeTable.Past}
            />
          ) : (
            <Typography>Вы еще не были на сеансах</Typography>
          )}
        </div>
      </Content>
    </div>
  );
};

export default SessionsPc;
