import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().startOf('day');

const DateSelect = ({ name }: {name: string}) => {
  const methods = useFormContext();
  return (
    <Controller
      control={methods.control}
      name={name}
      render={({ field }) => (
        <DatePicker
          {...field}
          format="YYYY-MM-DD HH:mm:ss"
          disabledDate={disabledDate}
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
        />
      )}
    />
  );
};

export default DateSelect;