import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import {
  ISpecialistListFilters,
  ISpecialistListFiltersQuery,
} from '1_shared/config/interfaces/ISpecialistListFilters';
import {
  Breadcrumbs,
  Content,
  Faq,
  Feedback,
  Footer,
  Page,
  StatisticsPsy,
  ToRead,
  Typography,
} from '1_shared/ui';
import { Header } from '4_widgets';
import SpecialistFilters from '4_widgets/SpecialistFilters/ui/SpecialistFilters';

import { RoutePath } from '../../../1_shared/config/routes';
import { ILocationSpec } from '../model/service/interfaces/ILocationSpec';
import useGetSpecialistList from '../model/service/useGetSpecialistList';

import SpecialistListByRegion from './SpecialistListByRegion/SpecialistListByRegion';

import styles from './SpecialistsPage.module.scss';


const breadCrumbs = [
  {
    title: (
      <Link to={RoutePath.MAIN}>
        <Typography type="description">PSYCHOLGI</Typography>
      </Link>
    ),
  },
  {
    title: (
      <Link to={RoutePath.SPECIALISTS}>
        <Typography type="description">СПЕЦИАЛИСТЫ ДЛЯ МЕНЯ</Typography>
      </Link>
    ),
  },
];

const SpecialistsPage: FC = () => {
  const location = useLocation();
  const state = location.state as ILocationSpec;

  const methods = useFormContext<ISpecialistListFilters>();

  const { specList, setFilters, viewMore, total, totalShowedSpecs } =
    useGetSpecialistList();

  const handleFiltersChange = (filters: ISpecialistListFiltersQuery) => {
    setFilters(filters.filter);
  };

  useEffect(() => {
    if (state?.filters) {
      methods.reset(state.filters);
      setFilters(state.filters);
      location.state = {};
      window.history.replaceState({}, '');
    }
  }, [methods, setFilters, state?.filters]);

  return (
    <Page>
      <Header />
      <Content wrapperClass={cn(styles.content)}>
      <Breadcrumbs items={breadCrumbs} />
        <div className={styles.headerText}>
        <Typography type="h2">Лучшие специалисты готовы вам помочь</Typography>

        </div>
        <div className={styles.catalogWrapper}>
        <div className={styles.filters}>
          <SpecialistFilters onChange={handleFiltersChange} />
        </div>
          {/** TODO: вынести в отдельный блок формирование листа специалистов согласно региону *126 */}
          <SpecialistListByRegion
            handleFiltersChange={handleFiltersChange}
            specList={specList}
            totalShowedSpecs={totalShowedSpecs}
            total={total}
            viewMore={viewMore}
          />
          {/** TODO: *126 */}
        </div>
      </Content>
      <ToRead />
      <Feedback />
      <StatisticsPsy />
      <Faq />
      <Footer />
    </Page>
  );
};

export default SpecialistsPage;
