import {
  EmergencyContacts,
  Footer,
  SurveyEmergency,
  ToRead,
} from '1_shared/ui';
import Page from '1_shared/ui/Page/ui/Page';
import { Header } from '4_widgets';

const ReactOrderNotOk = () => (
  <Page>
    <Header />
    <EmergencyContacts />
    <ToRead />
    <SurveyEmergency />
    <Footer />
  </Page>
);

export default ReactOrderNotOk;
